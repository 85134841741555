import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getNames } from "country-list";
const Form = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    country: "United States of America",
    zip_code: "",
    freegifts: true,
  });

  const countries = getNames().map((country) => ({
    value: country,
    label: country,
  }));
  const headingstyle = {
    fontFamily: "A Love of Thunder",
    wordSpacing: "1rem",
    color: "#fff",
    textborder: "1px solid #000000",
  };
  const textstyle = {
    fontFamily: "Proxima Nova ScOsf Semibold",
    color: "#fff",
    textborder: "1px solid #000000",
    textShadow: `
  1px 1px 0px #000000,
  -1px -1px 0px #000000,
  1px -1px 0px #000000,
  -1px 1px 0px #000000,
  0px 1px 0px #000000,
  0px -1px 0px #000000,
  1px 0px 0px #000000,
  -1px 0px 0px #000000`,
  };



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    const params = new URLSearchParams(formData).toString();
    navigate(`/vote?${params}`);
    e.preventDefault();
    if (!formData.freegifts) {
      alert("Please agree to the terms and conditions before submitting.");
      return;
    }
  };



  // const handleCancel = () => {
  //   navigate("/");
  //   setFormData({
  //     name: "",
  //     email: "",
  //     phone_number: "",
  //     country: "United States of America",
  //     zip_code: "",
  //     freegifts: true,
  //   });
  // };
  return (
    <div className="flex flex-col items-center justify-center mt-3   md:h-screen">
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-[100%] h-auto p-6 rounded-lg"
      >
        <div
          style={headingstyle}
          className="text-[30px] md:text-[50px] text-start"
        >
          <h1 className="sm:block ml-0 mt-[-3px] text-[#212060] text-shadow w-[200%] md:h-[100%] text-[5vw] md:ml-[-150px] md:mt-[200px] md:mb-[10px] lg:ml-0  lg:mb-5 lg:mt-[-50px]">
            Enter Your Info, Then Vote{" "}
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  ml-20  md:ml-10 gap-6 mt-[2%] sm:ml-20 ">
          <div className="mb-6 md:mb-1">
            <label
              className="block mt-[3%] mb-2 md:text-[30px] text-[22px] text-white"
              style={{
                fontFamily: "Proxima Nova ScOsf Semibold",
                textShadow: `
                       1px 1px 0px #000000,
                      -1px -1px 0px #000000,
                       1px -1px 0px #000000,
                      -1px 1px 0px #000000,
                       0px 1px 0px #000000,
                       0px -1px 0px #000000,
                       1px 0px 0px #000000,
                      -1px 0px 0px #000000`,
              }}
              htmlFor="name"
            >
              Name (required)
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-[80%] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Your Name"
            />
          </div>

          {/* Phone Field */}
          <div className="mb-6">
            <label
              className="block mb-2 md:text-[30px] text-[22px] text-white font-proxima-nova-condensed-bold md:mt-[-2rem]"
              style={{
                fontFamily: "Proxima Nova ScOsf Semibold",
                textShadow: `
                1px 1px 0px #000000,
                -1px -1px 0px #000000,
                1px -1px 0px #000000,
                -1px 1px 0px #000000,
                0px 1px 0px #000000,
                0px -1px 0px #000000,
                1px 0px 0px #000000,
                -1px 0px 0px #000000`,
              }}
              htmlFor="phone_number"
            >
              Phone Number (Optional)<br></br>
              For sms Text Update Only
            </label>
            <input
              type="tel"
              name="phone_number"
              id="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className="w-[80%] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="+1(312)555-0000"
            />
          </div>
          {/* Email Field */}
          <div className="mb-0">
            <label
              className="block mb-2 md:text-[30px] text-[22px] text-white font-proxima-nova-condensed-bold"
              style={{
                fontFamily: "Proxima Nova ScOsf Semibold",
                textShadow: `
                          1px 1px 0px #000000,
                          -1px -1px 0px #000000,
                          1px -1px 0px #000000,
                          -1px 1px 0px #000000,
                          0px 1px 0px #000000,
                          0px -1px 0px #000000,
                          1px 0px 0px #000000,
                          -1px 0px 0px #000000`,
              }}
              htmlFor="email"
            >
              Email (required)
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-[80%] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="name@domain.com"
            />
          </div>
          {/* Country Selection Field */}
          <div className="mb-6">
            <label
              className="block mb-2 md:text-[30px] text-[22px] text-white font-proxima-nova-condensed-bold"
              style={{
                fontFamily: "Proxima Nova ScOsf Semibold",
                textShadow: `
            1px 1px 0px #000000,
            -1px -1px 0px #000000,
            1px -1px 0px #000000,
            -1px 1px 0px #000000,
            0px 1px 0px #000000,
            0px -1px 0px #000000,
            1px 0px 0px #000000,
            -1px 0px 0px #000000`,
              }}
              htmlFor="country"
            >
              Select Country
            </label>

            <Select
              name="country"
              id="country"
              value={countries.find((c) => c.value === formData.country)}
              onChange={(selectedOption) =>
                handleChange({
                  target: { name: "country", value: selectedOption.value },
                })
              }
              options={countries}
              classNamePrefix="react-select"
              className="w-[80%] "
              placeholder="Select a country"
              styles={{
                control: (base) => ({
                  ...base,
                  padding: "8px 12px",
                  border: "1px solid #D1D5DB",
                  borderRadius: "0.5rem",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#3B82F6",
                  },
                  "&:focus": {
                    outline: "none",
                    borderColor: "#3B82F6",
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#9CA3AF",
                }),
              }}
            />
          </div>
          {/* Zip Code Field */}
          <div className="mb-6">
            <label
              className="block mb-2 md:text-[30px] text-[22px] text-white font-proxima-nova-condensed-bold"
              style={{
                fontFamily: "Proxima Nova ScOsf Semibold",
                textShadow: `
                           1px 1px 0px #000000,
                           -1px -1px 0px #000000,
                           1px -1px 0px #000000,
                           -1px 1px 0px #000000,
                           0px 1px 0px #000000,
                           0px -1px 0px #000000,
                           1px 0px 0px #000000,
                           -1px 0px 0px #000000`,
              }}
              htmlFor="zip_code"
            >
              ZipCode (required)
            </label>
            <input
              type="text"
              name="zip_code"
              id="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
              required
              className="w-[80%] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="000000"
            />
          </div>

          <div className="mb-6 ml-1  ">
            <label
              style={{ ...textstyle, wordSpacing: "0.1rem" }}
              className="text-[13px] md:text-[20px] -ml-10 flex items-center tracking-[0.1rem] md:tracking-[0.3rem]"
              htmlFor="freegifts"
            >
              <input
                type="checkbox"
                name="freegifts"
                id="freegifts"
                checked={formData.freegifts}
                onChange={handleChange}
                required
                className="mr-2 md:w-8 md:h-8"
              />
              <span
                className="block md:w-[10%] w-[150%] font-extrabold lg:font-extrabold lg:text-[28px] md:text-[15px] lg:text-xl sm:text-[12px]"
                style={{ width: "15%", whiteSpace: "nowrap" }}
              >
                TO KNOW WHAT IS REALLY GOING ON,<br></br>SUBSCRIBE TO
                WHATTHATMEANS.NET <br></br> AND RECEIVE ENDLESS VALUABLE FREE{" "}
                <br></br>GIFTS THAT WILL IMPROVE YOUR <br></br> HEALTH, WEALTH
                AND OVERALL LIFE <br></br>(CLICKOFF TO UNSUBSCRIBE) *
              </span>
            </label>
          </div>
        </div>

        {/* Buttons: Submit and Cancel */}
        <div className="flex md:gap-32 gap-28 lg:gap-64 flex-col md:flex-row items-center justify-center md:justify-center ">
          {/* <button
            type="button"
            onClick={handleCancel}
            className="bg-[#212060] ml-[3%] text-[#EBECED] md:py-4 md:px-10 py-3 px-6 rounded-lg font-extrabold text-lg md:text-2xl focus:outline-none w-[50%] md:w-[20%]"
          >
            BACK
          </button> */}

          <button
            type="submit"
            className="bg-[#212060] text-[#EBECED] md:py-4 md:px-10 py-3 px-6 rounded-lg font-extrabold text-lg md:text-2xl focus:outline-none w-[50%] md:w-[45%]"
          >
            GO VOTE
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
