import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import introimg from "../assets/imgs/panalone.png";

const Intro = () => {
  const [fadeOutImage, setFadeOutImage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "";

    const timer = setTimeout(() => {
      setFadeOutImage(true);
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <Helmet>
        <title>Be Counted at For The People</title>
      </Helmet>
      <div
        className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${
          fadeOutImage ? "fade-out" : "opacity-100"
        }`}
        style={{
          backgroundImage: `url(${introimg})`,
          backgroundSize: "contain",
          backgroundRepeat:"no-repeat",
          backgroundPosition: "center",
          height: "100vh",
        }}
        onClick={() => navigate("/home")}
      ></div>

      <style jsx>{`
        .fade-out {
          opacity: 0;
          transition: opacity 1s ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default Intro;
