import React, { useState } from 'react';
import Table from '../components/Table';
import Emails from '../components/Emails';
import Dashboardh1 from "../assets/svgs/Dashboardh1.svg";
import email from "../assets/imgs/emails.png";
import votes from "../assets/imgs/votes.png";

function Dashboard() {
  const [showEmails, setShowEmails] = useState(true);

  return (
    <div>
      <div className="container mx-auto px-4 py-2">
        <div className="mt-[1%] w-[40%]">
          <img src={Dashboardh1} alt="Dashboard" />
        </div>
        <div className="flex flex-row gap-20 mt-10 mb-10">
          <img
            src={email}
            alt="Dashboard"
            className="cursor-pointer"
            onClick={() => setShowEmails(true)}
          />
          <img
            src={votes}
            alt="Dashboard"
            className="cursor-pointer"
            onClick={() => setShowEmails(false)}
          />
        </div>
        {showEmails ? <Emails /> : <Table />}
      </div>
    </div>
  );
}

export default Dashboard;