import React from 'react'

const Main = ({children, className,style}) => {
  return (
    <main style={{...style}} className={`${className} p-3`}>
      {children}
    </main>
  )
}

export default Main
