import React from "react";
import popups from "../assets/imgs/popups.jpeg"
const Popup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white text-center p-4 rounded-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-lg font-bold"
        >
          &times;
        </button>
        <img src={popups} alt="Popup" className="mb-4" />
        {/* <p className="mb-4">This is your popup text!</p> */}
        <a
          href="https://whatthatmeans.nyc3.cdn.digitaloceanspaces.com/THANKYOUPRESIDENTTRUMP.pdf"
          download
          className="px-4  py-2 bg-blue-500 text-white rounded"
        >
          Download
        </a>
      </div>
    </div>
  );
};

export default Popup;
