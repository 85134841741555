import React, { useState } from "react";
import sorrypic from "../assets/imgs/sorrytwo.png";
import share from "../assets/svgs/whiteshare.svg";
import ShareModal from "../components/ShareModal";

function Sorry() {
  const [isShareVisible, setIsShareVisible] = useState(false);

  const handleShareClick = () => {
    setIsShareVisible(true);
  };

  const handleClose = () => {
    setIsShareVisible(false);
  };

  return (
    <div className="h-screen w-screen bg-cover bg-[#0a0439]">
      <a
        href="https://whatthatmeans.net"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className="relative h-screen w-screen bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${sorrypic})` }}
        ></div>{" "}
      </a>
      <img
        onClick={(event) => {
          event.stopPropagation();
          handleShareClick();
        }}
        src={share}
        alt="Second"
        className="absolute top-[60%] left-[82%] transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10"
      />

      {/* <div className=' text-center'>
<h1 className='text-[#51F0DC] text-3xl font-bold pt-[1%]'>
THE PEOPLE'S UNOFFICIAL VOTE IS CRITICAL
</h1></div>
<h2 className='text-[#C4F38C] text-2xl mt-[1%] text-center font-bold'>
GO NOW TO THE WHAT THAT MEANS WEBSITE<br></br>
AND LEARN THE FACTUAL TRUTH ABOUT THE<br></br>
SERIOUS ISSUES THAT AFFECT HONEST<br></br>
ELECTIONS, YOUR FUTURE, AND EARTH'S FATE.
</h2> */}

      {/* <div className="flex md:hidden items-center justify-center">
  <img src={sorry2} alt="sorry img" />
</div>


<div className="flex md:hidden items-center  justify-center">
  <img src={sorry3} alt="sorry img" />
</div> */}
      {isShareVisible && <ShareModal onClose={handleClose} />}
    </div>
  );
}

export default Sorry;
