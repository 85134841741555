import React, { useState, useEffect } from "react";
import cancel from "../assets/svgs/cancel.svg";
import share from "../assets/svgs/share.svg";
import img1 from "../assets/imgs/img1.png";
import img2 from "../assets/imgs/img2.png";
import img3 from "../assets/imgs/img3.png";
import img4 from "../assets/imgs/img4.png";
import img5 from "../assets/imgs/img5.png";
import vote1 from "../assets/svgs/vote1.svg";
import vote2 from "../assets/svgs/vote2.svg";
import vote3 from "../assets/svgs/vote3.svg";
import ShareModal from "../components/ShareModal";
import { useNavigate } from "react-router-dom";

function SorryPage() {
  const [isShareVisible, setIsShareVisible] = useState(false);
  const images = [img1, img2, img3, img4, img2];
  const voteimage = [vote1, vote2, vote3, vote1, vote2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const [hasClickedFreeGifts, setHasClickedFreeGifts] = useState(false);

  const handlenavigate = () => {
    setHasClickedFreeGifts(true);
    window.location.href = "https://whatthatmeans.net/";
  };

  const style = {
    fontFamily: "Proxima Nova Condensed Bold",
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      });
    }, 200);

    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!hasClickedFreeGifts) {
        window.location.href = "https://whatthatmeans.net/";
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [hasClickedFreeGifts]);

  const handleShareClick = () => {
    setIsShareVisible(true);
  };

  const handleClose = () => {
    setIsShareVisible(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center h-screen w-full overflow-x-hidden">
      <div className="w-full flex flex-col items-center md:flex-row justify-center">
        {/* Main content for larger screens */}
        <div className="md:mt-0 ml-[400px] md:w-[45%] mt-16 bg-white lg:w-[35%] w-[80%] sm:w-[80%] h-auto rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-center">
          <img
            src={cancel}
            alt="Sorry Image"
            className="w-16 h-16 md:w-28 md:h-28 mb-4"
          />
          <h1
            style={style}
            className="md:text-4xl text-3xl text-[#F92020] mb-4 md:mb-10"
          >
            Sorry!
          </h1>
          <p style={style} className="md:text-2xl text-xl text-[#F24E1E]">
            You can only vote once.
          </p>
        </div>

        {/* Share Button Section */}
        <div className="flex-shrink-0 mt-4 md:mt-0 h-[100%] lg:ml-72 md:ml-16">
          <img
            src={share}
            alt="share icon"
            className="w-20 md:w-24 h-auto cursor-pointer"
            onClick={handleShareClick}
          />
        </div>
      </div>

      {/* Mobile Share Button */}
      <div className="absolute top-0 right-0 mt-4 mr-4 md:hidden">
        <img
          src={share}
          alt="share icon"
          className="w-16 h-auto cursor-pointer"
          onClick={handleShareClick}
        />
      </div>

      {/* Mobile Text and Image */}
      <div className="md:hidden lg:hidden mt-16 bg-white w-[90%] sm:w-[90%] h-auto rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-center">
        <img
          src={cancel}
          alt="Sorry Image"
          className="w-16 h-16 md:w-28 md:h-28 mb-4"
        />
        <h1
          style={style}
          className="md:text-4xl text-xl text-[#F92020] mb-4 md:mb-10"
        >
          Sorry!
        </h1>
        <p style={style} className="md:text-2xl text-lg text-[#F24E1E]">
          You can only vote once.
        </p>
      </div>

      {/* Free Gifts Button */}
      <button
        onClick={handlenavigate}
        className="bg-[#212060] text-white px-10 text-xl font-semibold py-3 rounded-xl mt-8 md:mt-[2%]"
      >
        Free Gifts
      </button>

      {/* Share Modal */}
      {isShareVisible && <ShareModal onClose={handleClose} />}

      {/* Sliding images on mobile */}
      <div className="md:hidden mt-24 justify-around flex h-[17rem] sm:flex-row gap-4 sm:mt-9 bottom-0">
        <img
          src={images[currentImageIndex]}
          alt="Sliding images"
          className="block md:hidden object-contain w-full max-w-[300px] mx-auto"
        />
        <img
          src={voteimage[currentImageIndex]}
          alt="Sliding images"
          className="block md:hidden object-contain w-full max-w-[100px] mx-auto"
        />
      </div>

      {/* Share Modal on mobile */}
      {isShareVisible && <ShareModal onClose={handleClose} />}
    </div>
  );
}

export default SorryPage;
