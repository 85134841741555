import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import KHarris from "../assets/svgs/KHarris.svg";
import DTrump from "../assets/svgs/DTrump.svg";
// import Text from "../assets/svgs/Text.svg";
// import TRUMPTEXT from "../assets/svgs/TRUMPTEXT.svg";

import { useNavigate } from "react-router-dom";
import { voteApi } from "../api/api";
function Vote() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    country: "",
    zip_code: "",
    freegifts: true,
  });

  useEffect(() => {
    const name = queryParams.get("name");
    const email = queryParams.get("email");
    const phone_number = queryParams.get("phone_number");
    const country = queryParams.get("country");

    const selectedCountry =
      country.toLowerCase() === "united states of america" ? "US" : country;

    const zip_code = queryParams.get("zip_code");
    const freegifts = queryParams.get("freegifts") === "true";

    setFormData({
      name: name || "",
      email: email || "",
      phone_number: phone_number || "",
      country: selectedCountry || "",
      zip_code: zip_code || "",
      freegifts: freegifts,
    });
  }, [location.search]);
  const navigate = useNavigate();
  const voteone = async () => {
    try {
      const resp = await voteApi({ ...formData, candidate_id: 1 });
      navigate("/Success");
    } catch (error) {
      navigate("/sorry");
      console.error("Error voting for option one:", error);
    }
  };

  const votetwo = async () => {
    try {
      const resp = await voteApi({ ...formData, candidate_id: 2 });
      console.log("Vote Two Response:", resp);
      navigate("/Success");
    } catch (error) {
      navigate("/sorry");
      console.error("Error voting for option one:", error);
    }
  };
  const handleback = () => {
    navigate("/form");
  };


  const style = {
    fontFamily: "Proxima Nova ScOsf Bold, sans-serif",

    // fontSize: '28px',
    // color: '#fff',
    // textShadow: '3px 3px 5px rgba(0, 0, 0, 0.7)'
    textShadow: '1px 1px 5px rgba(255, 255, 255, 0.7)',
  };
  const textstyle = {
    fontFamily: "Proxima Nova ScOsf Semibold",
    // fontSize: '28px',
    // color: '#fff',
    // textShadow: '3px 3px 5px rgba(0, 0, 0, 0.7)'
  };

  return (
    <div>
      <div
        style={style}
        className="text-center mt-[3%] md:mt-[2%] text-[#373670] text-4xl lg:text-3vw lg:px-[5%]  lg:tracking-[8px] tracking-[4px]"
      >
        <h1>
          UNITED STATES <br></br> PRESIDENTIAL CANDIDATES
        </h1>
      </div>

      {/* <div
        style={style}
        className="text-center md:hidden mt-[3%] md:mt-[2%] text-[#373670] text-[30px] md:text-4xl  md:tracking-[0.3em]"
      >
        <h1>
          UNITED STATES <br></br> PRESIDENTIAL<br></br> CANDIDATES
        </h1>
      </div> */}

      {/* <div
        style={textstyle}
        className=" mt-[3%] ml-[10%] w-[80%]  md:hidden text-center p-3 bg-[#0B155A]"
      >
        <h1 className=" text-white font-extrabold text-lg tracking-[0.1em]">
          THIS IS AN UNOFFICIAL VOTE<br></br>
          BY THE PEOPLE, & FOR THE PEOPLE,<br></br>
          TO DEMONSTRATE OUR ACCURATE<br></br>
          LEADERSHIP PREFERENCES.
        </h1>
      </div> */}

      <div className=" flex  flex-col justify-between gap-x-2 items-center mt-[1%]  w-full lg:px-[4%] lg:b:[1%]">
        <div className="text-center bg-[#0B155A] mx-4 text-white lg:w-1/3  w-auto    py-3 lg:hidden">
          <h1
            style={textstyle}
            className="tracking-[2px] text-xl font-extrabold"
          >
            THIS IS AN UNOFFICIAL VOTE
            BY THE PEOPLE, AND FOR THE PEOPLE,
            TO DEMONSTRATE OUR HONEST
            AND ACCURATE LEADERSHIP PREFERENCES.
          </h1>
        </div>
        <div className="flex flex-row px-4 lg:flex-row w-full justify-center gap-4 lg:pb-[1%] pt-[1%] ">
          <div className=" md:mt-0 text-center lg:w-1/3 ">
            <img
              className="rounded-xl "
              src={DTrump}
              alt="vote1"
            />
            <h1
              style={style}
              className="ml-[-1%] md:ml-0  text-[34px] md:text-[38px] text-[#212060] tracking-[0.2rem] justify-center"          >
              DONALD TRUMP
            </h1>
            <button
              onClick={voteone}
              className="ml-[-1%] md:ml-0 bg-[#212060] text-white px-4 md:px-8 lg:px-9 lg:text-xl text-md font-semibold py-2 rounded-xl"
            >
              LOCK MY VOTE
            </button>
          </div>


          <div className="  mr-5  text-center bg-[#0B155A] hidden lg:block  ml-4  lg:w-1/3 lg:h-[80%]">
            <h1
              style={textstyle}
              className="text-white  px-2 pt-2 tracking-[0.2em]  text-[2.4vw]   font-extrabold"
            >
              THIS IS AN UNOFFICIAL<br></br>
               VOTE BY THE PEOPLE, AND
              FOR THE PEOPLE,
              TO DEMONSTRATE
              OUR HONEST
              AND ACCURATE
              LEADERSHIP
              PREFERENCES.
            </h1>

            {/* <img
              className="rounded-xl  w-full h-full object-cover"
              src={Text}
              alt="vote2"
            /> */}

          </div>

          <div className=" md:mt-0 text-center lg:w-1/3  ">
            <img
              className="rounded-xl "
              src={KHarris}
              alt="vote2"
            />
            <h1
              style={style}
              className="ml-[-1%] md:ml-0  text-[34px] md:text-[38px] text-[#212060] tracking-[0.2rem] justify-center"
            >
              KAMALA HARRIS
            </h1>
            <button
              onClick={votetwo}
              className="ml-[-1%] md:ml-0 bg-[#212060] text-white px-4 md:px-8 lg:px-9 lg:text-xl text-md font-semibold py-2 rounded-xl"
            >
              LOCK MY VOTE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vote;
