import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import IntroOne from "../components/IntroOne";
import IntroTwo from "../components/IntroTwo";
import Introthree from "../components/Introthree";
import Intro from "../components/Intro";

const SplashScreen = () => {
  const [currentComponent, setCurrentComponent] = useState(1);
  const navigate = useNavigate();

  const isSmallScreen = window.innerWidth < 768;

  useEffect(() => {
    let firstTimer, secondTimer, thirdTimer;

    if (isSmallScreen) {
      console.log("First Component shown on small screen");
      firstTimer = setTimeout(() => {
        setCurrentComponent(2);
        console.log("Switching to Second Component on small screen");
      }, 2500);

      secondTimer = setTimeout(() => {
        console.log("Redirecting to Home on small screen");
        navigate("/home");
      }, 10000);
    } else {
      console.log("First Component shown on larger screen");
      firstTimer = setTimeout(() => {
        setCurrentComponent(2);
        console.log("Switching to Second Component on larger screen");
      }, 2500);

      secondTimer = setTimeout(() => {
        setCurrentComponent(3);
        console.log("Switching to Third Component on larger screen");
      }, 5000);

      thirdTimer = setTimeout(() => {
        console.log("Redirecting to Home on larger screen");
        navigate("/home");
      }, 10000);
    }

    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
      if (thirdTimer) clearTimeout(thirdTimer);
    };
  }, [isSmallScreen, navigate]);

  return (
    <div style={{height:"100vh"}}>
      {/* For larger screens */}
      <div className="splash-screen">
        <Intro />
        {/* {currentComponent === 1 && <IntroOne />}
        {currentComponent === 2 && <IntroTwo />}
        {currentComponent === 3 && <Introthree />} */}
      </div>

      {/* For small screens */}
      {/* <div className="splash-screen md:hidden">
        {currentComponent === 1 && <IntroOne />}
        {currentComponent === 2 && <IntroTwo />}
      </div> */}
    </div>
  );
};

export default SplashScreen;
