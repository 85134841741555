import React, { useState, useEffect } from "react";

import smgroup from "../assets/svgs/smherobg1.svg";

import { Navigate, useNavigate } from "react-router-dom";

import img1 from "../assets/imgs/img1.png";

import img2 from "../assets/imgs/img2.png";

import img3 from "../assets/imgs/img3.png";

import img4 from "../assets/imgs/img4.png";

import vote1 from "../assets/svgs/vote1.svg";

import vote2 from "../assets/svgs/vote2.svg";

import vote3 from "../assets/svgs/vote3.svg";

import share from "../assets/svgs/share.svg";

import shareManue from "../components/ShareModal";

import ShareModal from "../components/ShareModal";

import { countvote } from "../api/api";

import Swal from "sweetalert2";

import Main from "../components/Div/Main";

import WhiteSubHeading from "../components/Span/WhiteSubHeading";

import { formatNumberWithCommas } from "../utils/formatString";

import PrimaryButton from "../components/Button/PrimaryButton";

import ShareImg from "../components/Img/ShareImg";

import Popup from "../components/Popup";

function Herosection({ navigatePath, buttonText, heading }) {

  const navigate = useNavigate();

  

  const [isPopupVisible, setIsPopupVisible] = useState(false); 

  const [isShareVisible, setIsShareVisible] = useState(false);

  const handleClosePopup = () => {

    setIsPopupVisible(false); 

  };



  const handleShareClick = () => {

    setIsShareVisible(true);

  };











  const h1style = {

    fontFamily: "Proxima Nova ScOsf Bold, sans-serif",

    color: "#fff",

    wordSpacing: "0.5rem",

    textShadow: `

      1px 1px 0px #000000,

      -1px -1px 0px #000000,

      1px -1px 0px #000000,

      -1px 1px 0px #000000,

      0px 1px 0px #000000,

      0px -1px 0px #000000,

      1px 0px 0px #000000,

      -1px 0px 0px #000000`,

  };



  const handleClose = () => {

    setIsShareVisible(false);

  };



  const handlenavigate = () => {

    navigate(navigatePath);

  };



  const [voteCounts, setVoteCounts] = useState({

    trumpGlobalVote: 0,

    trumpUsVote: 0,

    HarrisGlobalVote: 0,

    HarrisUsVote: 0,

  });



  const totalVotes = 250;

  const images = [img1, img2, img3, img4, img2];

  const voteimage = [vote1, vote2, vote3, vote1, vote2];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  useEffect(() => {

    const intervalId = setInterval(() => {

      setCurrentImageIndex((prevIndex) => {

        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;

      });

    }, 200);



    return () => clearInterval(intervalId);

  }, [images.length]);



  useEffect(() => {

    const fetchVoteCount = async () => {

      try {

        const resp = await countvote();

        setVoteCounts({

          trumpGlobalVote: resp.votes[0].GlobalVoteCount,

          trumpUsVote: resp.votes[0].USVoteCount,

          HarrisGlobalVote: resp.votes[1].GlobalVoteCount,

          HarrisUsVote: resp.votes[1].USVoteCount,

        });

      } catch (error) {

        console.error("Error fetching vote count:", error);

      }

    };



    fetchVoteCount();

  }, []);



  return (

    <Main style={{"height":"98vh"}} className={"flex flex-col justify-center items-center"}>

      <div className="flex justify-center">

        {heading({ className: "text-center" })}

      </div>

      <div className="hidden md:block">

        <div className="flex justify-center">



          {window.location.pathname !== "/hero2" && <ShareImg

            style={{ "height": "20vh", width: "auto", marginTop: "15vh" }}

            src={images[currentImageIndex]} />}

           <ShareImg style={{"height":"auto", width:"70vw", paddingLeft: "50px", paddingRight: "50px"}} src={smgroup}/>



          <div

            onClick={handleShareClick}

            className={`flex flex-col   ${window.location.pathname === "/hero2" ? "justify-end pb-16" : "justify-evenly"}`}>

            {window.location.pathname !== "/hero2" && <ShareImg style={{ "height": "20vh", width: "auto" }} src={voteimage[currentImageIndex]} />}

            <ShareImg src={share} onClick={handleShareClick} />

          </div>

        </div>

      </div>

      {/* for sm screen */}

      <div className=" md:hidden">

        <div className="flex flex-col md:flex-row items-center gap-[5%] mt-[3%] lg:mt-0 lg:gap-[10%] justify-center">

          <div className=" flex flex-row gap-[3%]">

            <img

              src={images[currentImageIndex]}

              alt="Sliding images"

              className="ml-[9%] w-[13%] h-[10%] mt-[6%]"

            />

            <img

              className="h-[90%] w-[70%] mt-[5%] "

              src={smgroup}

              alt="background-img"

            />

          </div>

          <div className=" flex flex-row gap-[6%]">

            <img

              className="h-[90%] w-[70%] mt-[-5%] "

              src={smgroup}

              alt="background-img"

            />

            <div className="flex h-100">

              <img

                src={voteimage[currentImageIndex]}

                alt="Sliding images"

                className=" w-[45%] h-[90%]"

              />

              <ShareImg src={share} onClick={handleShareClick} />

            </div>

          </div>

        </div>

      </div>



      {/*  */}

      <div className="flex flex-col items-center justify-center md:hidden ">

        <button

          onClick={handlenavigate}

          className="block w-fit py-2  md:hidden px-10  text-xl md:text-2xl rounded-lg text-white bg-[#212060]"

        >

          {buttonText}

        </button>

        {/* <div onClick={shareManue} className="  ml-[20%]  mt-[3%]">

          <img

            src={share}

            alt="share icon"

            className="w-[50%] md:w-[80%] h-[80%] cursor-pointer"

            onClick={handleShareClick}

          />

        </div> */}

      </div>



      <div className="flex w-100 ">

        <div className="flex flex-row md:justify-evenly justify-between w-100 ">

          <div className="flex flex-col">



            <div className="flex flex-col md:flex-row ">

              <WhiteSubHeading>

                USA Trump Votes:

              </WhiteSubHeading>

              <WhiteSubHeading className={"md:ps-0"}>

                {formatNumberWithCommas(voteCounts.trumpUsVote)}

              </WhiteSubHeading>

            </div>



            <div className="flex flex-col md:flex-row ">

              <WhiteSubHeading>

                USA Harris Votes:

              </WhiteSubHeading>

              <WhiteSubHeading className={"md:ps-0"}>

                {formatNumberWithCommas(voteCounts.HarrisUsVote)}

              </WhiteSubHeading>

            </div>



          </div>

          <div className="flex justify-center">

            <PrimaryButton hideOnSmall={true} onClick={handlenavigate}>

              {buttonText}

            </PrimaryButton>

          </div>



          <div className="flex flex-col">

            <div className="flex flex-col md:flex-row ">

              <WhiteSubHeading>

                Global Trump Votes:

              </WhiteSubHeading>

              <WhiteSubHeading className={" md:ps-0"}>

                {formatNumberWithCommas(voteCounts.trumpGlobalVote)}

              </WhiteSubHeading>

            </div>



            <div className="flex flex-col md:flex-row ">

              <WhiteSubHeading>

                Global Harris Votes:

              </WhiteSubHeading>

              <WhiteSubHeading className={"md:ps-0"}>

                {formatNumberWithCommas(voteCounts.HarrisGlobalVote)}

              </WhiteSubHeading>

            </div>



          </div>

        </div>

      </div>

      {isPopupVisible && <Popup onClose={handleClosePopup} />} 

      {isShareVisible && <ShareModal onClose={handleClose} />}

      <div className="text-center mt-8">
        <a href="https://thepeoplesvote.us/articles/" className="text-black font-bold text-2xl underline">
          The Peoples Vote supports Google’s Trends and articles for the latest news, interests, and events around the world.
        </a>
      </div>

    </Main>

  );

}



export default Herosection;