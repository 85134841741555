import React from 'react'

function Loading() {
  return (
    <div class="flex items-center justify-center h-screen">
    <button
      type="button"
      class="bg-indigo-500 text-white font-extrabola py-4 px-6 rounded flex items-center text-xl"
      disabled
    >
      <svg
        class="animate-spin h-8 w-8 mr-3 text-white"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
          class="opacity-25"
        ></circle>
        <path
          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
          fill="currentColor"
          class="opacity-75"
        ></path>
      </svg>
      Loading...
    </button>
  </div>
  )
}
  
export default Loading