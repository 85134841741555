import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fetchTrends } from "../api/api";


const TrendingTags = () => {
  const [trends, setTrends] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTrends = async () => {
      const data = await fetchTrends();
      if (data && data.trends) {
        const trendQueries = data.trends.map((trend) => trend.title);
        setTrends(trendQueries);
      }
      setLoading(false);  // Set loading to false once trends are fetched
    };

    loadTrends();
  }, []);

  // Fallback title and description
  const defaultTitle = "Be Counted at For The People";
  const defaultDescription =
    "It's been said that For The People is essential to democracy. Your participation grants you access to many valuable life-changing gifts. Don't miss out...";

  const title =  defaultTitle;
  const description = defaultDescription;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={trends.join(", ")} />
      </Helmet>
    </>
  );
};

export default TrendingTags;
