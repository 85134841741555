import React from 'react'

const WhiteSubHeading = ({children, className}) => {
    const style = {
        fontFamily: "Proxima Nova ScOsf Bold, sans-serif",
        color: "#fff",
        wordSpacing: "-3px",
        letterSpacing:"1px",
        textShadow: `
          2px 2px 3px rgba(0, 0, 0, 0.2),
          -2px -2px 3px rgba(0, 0, 0, 0.2),
          2px -2px 3px rgba(0, 0, 0, 0.2),
          -2px 2px 3px rgba(0, 0, 0, 0.2),
          0px 2px 3px rgba(0, 0, 0, 0.2),
          0px -2px 3px rgba(0, 0, 0, 0.2),
          2px 0px 3px rgba(0, 0, 0, 0.2),
          -2px 0px 3px rgba(0, 0, 0, 0.2)`,
      };

  return (
    <span 
    style={{
        // fontSize: "clamp(1.2rem, 2.0vw, 35.0rem)",
        ...style
    }} 
    className={`${className} whitesubheading`}>
        {children}
    </span>
  )
}

export default WhiteSubHeading
