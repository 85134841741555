import React from 'react'
import "./img.css"

const ShareImg = ({src, onClick,className,style}) => {
  return (
    <img
    src={src}
    alt="share icon"
    className={`shareimg ${className}`}
    onClick={onClick}
    style={{...style}}
  />
  )
}

export default ShareImg
