import React, { useState, useEffect } from "react";
import Form from "../components/Form";
import img1 from "../assets/imgs/img1.png";
import img2 from "../assets/imgs/img2.png";
import img3 from "../assets/imgs/img3.png";
import img4 from "../assets/imgs/img4.png";
import img5 from "../assets/imgs/img5.png";
import vote1 from "../assets/svgs/vote1.svg";
import vote2 from "../assets/svgs/vote2.svg";
import vote3 from "../assets/svgs/vote3.svg";

function Enterdetails() {
  const images = [img1, img2, img3, img4, img2];
  const voteimage = [vote1, vote2, vote3, vote1, vote2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      });
    }, 200);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const fieldstyle = {
    fontFamily: "Proxima Nova ScOsf Semibold",
    fontSize: "28px",
    color: "#fff",
    textShadow: `
      1px 1px 0px #000000,
      -1px -1px 0px #000000,
      1px -1px 0px #000000,
      -1px 1px 0px #000000,
      0px 1px 0px #000000,
      0px -1px 0px #000000,
      1px 0px 0px #000000,
      -1px 0px 0px #000000`,
  };
  return (
    <div className="flex flex-col items-center overflow-x-hidden h-screen">
      <div className="flex flex-col w-full md:flex-row items-center gap-[5%] mt-[5%] md:mt-0 lg:gap-[0%] justify-center h-full">
        <img
          src={images[currentImageIndex]}
          alt="Sliding images"
          className="w-[9%] mt-[-22px] ml-24 hidden sm:block object-cover"
        />

        <div className="md:w-full h-screen">
          <Form />
        </div>
        <div className="">
          <img
            src={voteimage[currentImageIndex]}
            alt="Sliding images"
            className="mr-32 w-[50%] hidden sm:block object-cover mb-32"
          />
        </div>
      </div>
      <div className="w-[500%] hidden lg:block">
        <h1
          style={fieldstyle}
          className="text-center underline text-white w-full h-[120%] text-[10vw] ml-0 lg:text-3xl lg:mt-[-50px]"
        >
          *YOUR INFORMATION IS NEVER DISTRIBUTED OR DISCLOSED TO ANY THIRD
          PARTIES.
        </h1>
      </div>
    </div>
  );
}

export default Enterdetails;
