import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-13E5DMK9TW', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
}

export default AnalyticsTracker;
