import React, { useState } from "react";
import ResetPasswordh1 from "../assets/svgs/ResetPasswordh1.svg";
import lock from "../assets/svgs/lock.svg";

const ResetPassword = () => {
  const [newpassword, setNewPassword] = useState("");
  const [newconfirmpassword, setConfirmNewPassword] = useState("");

  const handleForgetpassword = (event) => {
    event.preventDefault();
    if(newpassword===newconfirmpassword){
    console.log("newpassword:", newpassword);
    console.log("newconfirmpassword:", newconfirmpassword);
    alert("Password Chnaged ")

    }
    else{
        alert("Password not match")
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {/* Image for ResetPasswordh1 */}
      <div className="mb-[3%]">
        <img src={ResetPasswordh1} alt="ResetPassword" className="w-[98%] object-cover" />
      </div>

      {/* Form Fields */}
      <form onSubmit={handleForgetpassword} className="w-full max-w-3xl px-4">
        {/* NEW PASSWORD */}
        <div className="mb-[5%]">
          <div className="flex items-center bg-white rounded-md px-3 py-3 shadow-sm">
            <img src={lock} alt="lock Icon" />
            <input
              type="password"
              placeholder="ENTER NEW PASSWORD"
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-2 py-2 text-xl text-center placeholder:text-center placeholder:text-[#212060] focus:outline-none"
              required
            />
          </div>
        </div>

        {/*RE Enter Password */}
        <div className="mb-[12%]">
          <div className="flex items-center bg-white rounded-md px-3 py-3 shadow-sm">
            <img src={lock} alt="Lock Icon" />
            <input
              type="password"
              placeholder="RE ENTER NEW PASSWORD"
              value={newconfirmpassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full px-2 py-2 text-xl text-center placeholder:text-center placeholder:text-[#212060] focus:outline-none"
              required
            />
          </div>
        </div>

        {/* Proceed Button */}
        <div>
          <button
            type="submit"
            className="w-full py-4 text-3xl font-bold text-white bg-[#212060] rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            PROCEED
          </button>
        </div>

    
      </form>
    </div>
  );
};

export default ResetPassword;
