import React, { useState } from "react";
import ForgotPasswordh1 from "../assets/svgs/ForgotPasswordh1.svg";
import emial from "../assets/svgs/email.svg";
import resetemailh2 from "../assets/svgs/resetemailh2.svg";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");

  const handleEmail = (event) => {
    event.preventDefault();
    console.log("email:", email);
    alert("email sended")
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {/* Image for ForgotPasswordh1 */}
      <div className="mb-[2%] px-3">
        <img src={ForgotPasswordh1} alt="ResetPassword" className="w-[98%] object-cover" />
      </div>

      <div className="mb-[3%] px-8">
        <img src={resetemailh2} alt="You will Recive a link to your email"/>
      </div>

      {/* Form Fields */}
      <form onSubmit={handleEmail} className="w-full max-w-3xl px-4">
        {/* Emial */}
        <div className="mb-[5%]">
          <div className="flex items-center bg-white rounded-md px-3 py-3 shadow-sm">
            <img src={emial} alt="lock Icon" />
            <input
              type="email"
              placeholder="ENTER YOUR EMAIL"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-2 py-2 text-xl text-center placeholder:text-center placeholder:text-[#212060] focus:outline-none"
              required
            />
          </div>
        </div>

        {/* Check your email Button */}
        <div>
          <button
            type="submit"
            className="w-full py-4 text-3xl font-bold text-white bg-[#212060] rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            CHECK YOUR EMAIL
          </button>
        </div>

    
      </form>
    </div>
  );
};

export default Forgotpassword;
