import React from 'react'
import "./button.css"
import WhiteSubHeading from '../Span/WhiteSubHeading'

const PrimaryButton = ({ className, children, onClick,hideOnSmall, type }) => {
    const hideOnSmallClass=hideOnSmall?"hidesmall":""
    return (
        <button type={type} className={`${className} primarybutton ${hideOnSmallClass}`} onClick={onClick}>
            <WhiteSubHeading>
                {children}
            </WhiteSubHeading>
        </button>
    )
}

export default PrimaryButton
