import React from 'react'
import "./heading.css"

const WhiteHeading = ({children, className}) => {

  return (
    <span 
    className={`${className} whiteheading`}>
        {children}
    </span>
  )
}

export default WhiteHeading
