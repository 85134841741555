import React, { useState, useEffect } from "react";
import FilterIcon from "../assets/svgs/FilterIcon.svg";
import SearchIcon from "../assets/svgs/SearchIcon.svg";
import Previous from "../assets/svgs/Previous.svg";
import next from "../assets/svgs/next.svg";

import down_outline from "../assets/svgs/down-outline.svg";
import { fetchVotes } from "../api/api";
import { getNames, getCode, getData } from "country-list";
import Loading from "../components/Loading";

const Table = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchName, setSearchName] = useState("");

  const [filterCountry, setFilterCountry] = useState("");
  const [filterZip, setFilterZip] = useState("");
  const [filterType, setFilterType] = useState("country");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [votesData, setVotesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageState, setPageState] = useState([]);
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const [pageno, setPageno] = useState(1);

  const countries = getNames().map((country) => ({
    value: country,
    label: country,
  }));
  console.log(" page", pageno);
  const filteredData = votesData.filter(
    (item) =>
      (item.Name.toUpperCase().includes(searchName.toUpperCase()) ||
        item.Email.toUpperCase().includes(searchTerm.toUpperCase())) &&
      (filterType === "country"
        ? item.Country.toUpperCase().includes(filterCountry.toUpperCase())
        : item.ZipCode.includes(filterZip))
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const handlePageClick = () => {
    console.log(votesData.length);
    if (votesData.length < 25) {
      setPageno(pageno);
    } else {
      setPageno(pageno + 1);
    }
  };
  const handlePageClickback = () => {
    if (pageno === 1) {
      setPageno(pageno);
    } else {
      setPageno(pageno - 1);
    }
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setShowFilterModal(false);
    setCurrentPage(1);
  };

  const getVotes = async () => {
    setLoading(true);
    setError("");
    console.log("Fetching votes for page state:", pageState.next_Page);
    try {
      const requestData = {
        page_state: "",
        search_term: {
          column: searchTerm ? "email" : "name" || "",
          search_text: searchTerm || searchName || "",
        },
        country: filterCountry || "",
        zip_code: filterZip || "",
      };
      const response = await fetchVotes(requestData);

      if (Array.isArray(response.data.votes)) {
        setVotesData(response.data.votes);
        setPageState(response.data.votes);
        console.log("Next page state:", response.data.votes.next_Page);
      } else if (response.data && Array.isArray(response.data.votes.Votes)) {
        setVotesData(response.data.votes.Votes);
        setPageState(response.data.votes);
      } else {
        console.error("Unexpected response format", response);
        setVotesData([]);
      }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getNextVotes = async () => {
    setLoading(true);
    setError("");
    console.log("Fetching votes for page state:", pageState.next_Page);
    try {
      const requestData = {
        // limit: 7,
        page_state: pageState.next_Page,
        SearchTerm: searchTerm || "",
        country: filterCountry || "",
        zip_code: filterZip || "",
      };
      const response = await fetchVotes(requestData);

      if (Array.isArray(response.data.votes)) {
        setVotesData(response.data.votes);
        setPageState(response.data.votes);
        console.log("Next page state:", response.data.votes.next_Page);
      } else if (response.data && Array.isArray(response.data.votes.Votes)) {
        setVotesData(response.data.votes.Votes);
        setPageState(response.data.votes);
      } else {
        console.error("Unexpected response format", response);
        setVotesData([]);
      }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const nextPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setPageState((prevPageState) => ({
      ...prevPageState,
      page: Math.max(prevPageState.page - 1, 1),
    }));
    getNextVotes();
    handlePageClick();
  };

  const previousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setPageState((prevPageState) => ({
      ...prevPageState,
      page: Math.max(prevPageState.page - 1, 1),
    }));
    getVotes();
    handlePageClickback();
  };

  useEffect(() => {
    getVotes();
    setPageno(1);
  }, [searchTerm, filterCountry, filterZip, searchName]);

  console.log("here is data ", votesData);

  return (
    <>
     
      {/* Search and Filter Section */}
      <div className="flex flex-row  justify-between items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="relative  sm:w-[50%] text-[#87878B]">
          <img
            src={SearchIcon}
            alt="Search"
            className="absolute left-5 top-5 h-5 w-5"
          />
          <input
            type="text"
            placeholder="Search Email"
            className="pl-10 w-full text-center py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder:text-lg md:placeholder:text-2xl placeholder:font-bold placeholder:italic"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* search by name */}
        <div className="relative  sm:w-[50%] text-[#87878B]">
          <img
            src={SearchIcon}
            alt="Search"
            className="absolute left-5 top-5 h-5 w-5"
          />
          <input
            type="text"
            placeholder="Search Name"
            className="pl-10 w-full text-center py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder:text-lg md:placeholder:text-2xl placeholder:font-bold placeholder:italic"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        {/* Filter Button to open modal */}
        <button
          onClick={() => setShowFilterModal(true)}
          className="flex items-center text-xl w-[25%] lg:w-[10%] px-2 lg:px-4 py-3 font-bold bg-white text-[#4F4F4F] rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Filter
          <img src={FilterIcon} alt="Filter" className="h-5 w-5 ml-1 lg:ml-6" />
        </button>
      </div>

      {/* Filter Modal */}
      {showFilterModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gradient-to-r from-[#8AA7F3] to-[#FEA5B9] rounded-lg shadow-lg p-6 w-80 md:w-[40%] md:h-[55%]">
            <form onSubmit={handleFilterSubmit}>
              <div className="mb-4">
                <label className="block text-xl md:text-3xl text-[#212060] font-bold mb-2">
                  Search By
                </label>

                <div className="relative w-full">
                  <select
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    className="w-full text-xl md:text-3xl font-extrabold border text-[#718EBF] rounded-lg p-4 appearance-none"
                  >
                    {/* Dropdown options */}
                    <option
                      className="font-extrabold text-xl md:text-3xl"
                      value="country"
                    >
                      Country
                    </option>
                    <option
                      className="font-extrabold text-xl md:text-3xl"
                      value="zip"
                    >
                      Zip Code
                    </option>
                  </select>
                  {/* SVG icon for the dropdown */}
                  <img
                    src={down_outline}
                    alt="Dropdown icon"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
                  />
                </div>
              </div>

              {/* Input field based on selected filter type */}
              {filterType === "country" ? (
                <div className="mb-4 text-xl md:text-3xl font-bold">
                  <label className="block mb-2 text-[#212060]">
                    Choose Country
                  </label>
                  <div className="relative w-full">
                    <select
                      value={filterCountry ? filterCountry : ""}
                      onChange={(e) => setFilterCountry(e.target.value)}
                      className="w-full text-xl md:text-3xl font-bold border text-[#718EBF] rounded-lg p-4 appearance-none"
                    >
                      <option value="" disabled hidden>
                        United States of America
                      </option>
                      {getData().map((country) => (
                        <option
                          key={country.code}
                          value={country.code.toUpperCase()}
                          className="font-extrabold text-xl md:text-3xl"
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {/* SVG icon for the dropdown */}
                    <img
                      src={down_outline}
                      alt="Dropdown icon"
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
                    />
                  </div>
                </div>
              ) : (
                <div className="mb-4 text-xl md:text-3xl font-bold">
                  <label className="block mb-2 text-[#212060]">
                    Enter ZipCode
                  </label>
                  <input
                    type="text"
                    placeholder="00000"
                    className="w-full border rounded-lg p-3 placeholder:text-[#718EBF]"
                    value={filterZip}
                    onChange={(e) => setFilterZip(e.target.value)}
                  />
                </div>
              )}

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setShowFilterModal(false)}
                  className="md:px-12 px-4 py-3 bg-[#ff4470] text-xl md:text-3xl font-extrabold text-white rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="md:px-12 px-4 py-3 bg-[#212060] text-xl md:text-3xl font-extrabold text-white rounded-lg"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Table */}

      <div className="overflow-x-auto " style={{ height: "67vh" }}>
        {loading ? (
          <Loading/>
     
        ) : error ? (
          <p className="text-center text-xl text-red-500">{error}</p>
        ) : (
          <table className="min-w-full bg-white rounded-xl overflow-hidden">
            <thead>
              <tr className="bg-white text-[#718EBF] leading-normal">
                <th className="py-3 px-8 text-left rounded-tl-xl text-lg md:text-2xl font-extrabold">
                  Name
                </th>
                <th className="py-3 px-4 text-left text-lg md:text-2xl font-extrabold">
                  Country
                </th>
                <th className="py-3 px-4 text-left text-lg md:text-2xl font-extrabold">
                  Zip Code
                </th>
                <th className="py-3 px-4 text-left text-lg md:text-2xl font-extrabold">
                  Phone Number
                </th>
                <th className="py-3 px-4 text-left text-lg md:text-2xl font-extrabold">
                  Email
                </th>
                <th className="py-3 px-4 text-left text-lg rounded-tr-xl md:text-2xl font-extrabold">
                  Free Gifts
                </th>
              </tr>
              <tr>
                <td colSpan="6">
                  <div className="border-b-4 border-black w-[95%] ml-[2.5%] " />
                </td>
              </tr>
            </thead>

            <tbody className="text-[#232323] text-lg font-bold md:text-2xl">
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr
                    key={item.id}
                    className={`border-b border-gray-200 hover:bg-gray-100 ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  >
                    <td className="py-3 px-8">{item.Name}</td>
                    <td className="py-3 px-4">{item.Country}</td>
                    <td className="py-3 px-4">{item.ZipCode}</td>
                    <td className="py-3 px-4">{item.PhoneNumber}</td>
                    <td className="py-3 px-4 text-[#FE5C73]">{item.Email}</td>
                    <td className="py-3 px-4">
                      {item.FreeGifts ? "True" : "False"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-end mt-4 ">
        <img
          src={Previous}
          className=" cursor-pointer"
          onClick={previousPage}
        />

        <button
          onClick={previousPage}
          className=" px-4 py-2  text-white rounded-lg cursor-pointer  "
        >
          Previous
        </button>

        {[totalPages].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`mx-1 px-3 py-1 rounded-lg ${
              currentPage === index + 1 ? "bg-white text-black" : " text-white"
            }`}
          >
            {pageno}
          </button>
        ))}
        <button
          onClick={nextPage}
          disabled={votesData.length < 25}
          className="mx-2 px-4 py-2 text-white rounded-lg cursor-pointer"
        >
          Next
        </button>
        <img
          src={next}
          className=" cursor-pointer "
          onClick={votesData.length >= 25 ? nextPage : null}
        />
      </div>
    </>
  );
};

export default Table;
