import React, { useState, useEffect } from "react";
import Successimg from "../assets/svgs/Success.svg";
import share from "../assets/svgs/share.svg";
import img1 from "../assets/imgs/img1.png";
import img2 from "../assets/imgs/img2.png";
import img3 from "../assets/imgs/img3.png";
import img4 from "../assets/imgs/img4.png";
import img5 from "../assets/imgs/img5.png";
import vote1 from "../assets/svgs/vote1.svg";
import vote2 from "../assets/svgs/vote2.svg";
import vote3 from "../assets/svgs/vote3.svg";
import ShareModal from "../components/ShareModal";
import { useNavigate } from "react-router-dom";

function Success() {
  const [isShareVisible, setIsShareVisible] = useState(false);
  const images = [img1, img2, img3, img4, img2];
  const voteimage = [vote1, vote2, vote3, vote1, vote2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const [hasClickedFreeGifts, setHasClickedFreeGifts] = useState(false);

  const handlenavigate = () => {
    setHasClickedFreeGifts(true);
    window.location.href = "https://whatthatmeans.net/";
  };

  const style = {
    fontFamily: "Proxima Nova Condensed Bold",
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      });
    }, 200);

    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!hasClickedFreeGifts) {
        window.location.href = "https://whatthatmeans.net/";
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [hasClickedFreeGifts]);

  const handleShareClick = () => {
    setIsShareVisible(true);
  };

  const handleClose = () => {
    setIsShareVisible(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center h-screen w-[100%] overflow-x-hidden">
      <div className="w-full lg:flex flex-col md:flex-row justify-center items-center hidden sm:flex">
        <div className="md:mt-0 md:ml-[280px] md:w-[45%] mt-52 bg-white lg:w-[35%] w-[40%] sm:w-[40%] h-auto rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-center lg:ml-[490px] ">
          <img
            src={Successimg}
            alt="Sorry Image"
            className="w-16 h-16 md:w-28 md:h-28 mb-4"
          />
          <h1
            style={style}
            className="md:text-4xl text-3xl text-[#2F8C17] mb-4 md:mb-10"
          >
Congratulations
          </h1>
          <p style={style} className="md:text-2xl text-xl text-[#2F8C17]">
          You have voted Successfully.
          </p>
        </div>
        <div className="flex-shrink-0 mt-4 md:mt-0 h-[100%] lg:ml-72 md:ml-16">
          <img
            src={share}
            alt="share icon"
            className="w-[70%] md:w-[50%] h-[100%] cursor-pointer"
            onClick={handleShareClick}
          />
        </div>
      </div>

      <div className="absolute top-0 right-0 mt-4 mr-4 lg:hidden md:hidden">
        <img
          src={share}
          alt="share icon"
          className="w-[70%] md:w-[50%] h-auto cursor-pointer"
          onClick={handleShareClick}
        />
      </div>
      <div className="md:hidden lg:hidden mt-52 bg-white w-[60%] sm:w-[60%] h-auto rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-center">
        <img
          src={Successimg}
          alt="Sorry Image"
          className="w-16 h-16 md:w-28 md:h-28 mb-4"
        />
        <h1
          style={style}
          className="md:text-4xl text-xl text-[#2F8C17] mb-4 md:mb-10"
        >
Congratulations
        </h1>
        <p style={style} className="md:text-2xl text-lg text-[#2F8C17]">
          You have voted Successfully.
        </p>
      </div>

      <button
        onClick={handlenavigate}
        className="bg-[#212060] text-white px-10 text-xl font-semibold py-3 rounded-xl mt-8 md:mt-[2%]"
      >
        Free Gifts
      </button>

      {isShareVisible && <ShareModal onClose={handleClose} />}

      <div className="md:hidden mt-24 justify-around flex h-[17rem] sm:flex-row gap-4 sm:mt-9 bottom-0">
        <img
          src={images[currentImageIndex]}
          alt="Sliding images"
          className="block md:hidden object-contain"
        />
        <img
          src={voteimage[currentImageIndex]}
          alt="Sliding images"
          className="block md:hidden object-contain"
        />
      </div>

      {isShareVisible && <ShareModal onClose={handleClose} />}
    </div>
  );
}

export default Success;