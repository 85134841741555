import React, { useState } from "react";
import loginh1 from "../assets/svgs/loginh1.svg";
import user from "../assets/svgs/user.svg";
import lock from "../assets/svgs/lock.svg";
import Forgotpassword from "../assets/svgs/Forgot_password.svg";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../api/api";
import Swal from 'sweetalert2';
const Login = () => {
  const navigate=useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


 const handleLogin = async (event) => {
  event.preventDefault();

  try {
    const resp = await loginApi(email, password);

    // Check for successful response
    if (resp.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Login Successful!',
        text: 'You are now being redirected to the dashboard.',
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/dashboard");
    } else {
      // Handle other statuses that indicate failure
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: resp.data.message || 'An unexpected error occurred. Please try again.',
      });
    }

  } catch (error) {
    console.error("Login error:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred during login. Please try again later.',
    });
  }

  console.log("email:", email);
  console.log("Password:", password);
}; 
  const handleForgotPassword =()=>{
    navigate("/forgotpassword")
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {/* Image for loginh1 */}
      <div className="mb-[3%]  ">
        <img src={loginh1} alt="Login" className="w-[98%] object-cover" />
      </div>

      {/* Form Fields */}
      <form onSubmit={handleLogin} className="w-full max-w-3xl px-4">
        {/* email */}
        <div className="mb-[5%]">
          <div className="flex items-center bg-white rounded-md px-3 py-3 shadow-sm">
            <img src={user} alt="User Icon" />
            <input
              type="text"
              placeholder="ENTER EMAIL"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-2 py-2 text-xl text-center placeholder:text-center placeholder:text-[#212060] focus:outline-none"
              required
            />
          </div>
        </div>

        {/* Password */}
        <div className="mb-[7%]">
          <div className="flex items-center bg-white rounded-md px-3 py-3 shadow-sm">
            <img src={lock} alt="Lock Icon" />
            <input
              type="password"
              placeholder="ENTER PASSWORD"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-2 py-2 text-xl text-center placeholder:text-center placeholder:text-[#212060] focus:outline-none"
              required
            />
          </div>
        </div>

        {/* Login Button */}
        <div>
          <button
            type="submit"
            className="w-full py-4 text-3xl font-bold text-white bg-[#212060] rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            LOGIN
          </button>
        </div>

        {/* Forgot Password */}
        {/* <div className="mt-10 text-right">
          <button onClick={handleForgotPassword} type="button">
            <img className="inline-block" src={Forgotpassword} alt="Forgot Password" />
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default Login;
